<template>
<div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { validateTruelayerCode } from '@/api/truelayer'
import { LSKeys } from '@/utils/constants'

export default {
  data () {
    return {
      verification: null
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    })
  },
  methods: {
    truelayerVerificationFlow () {
      return validateTruelayerCode({
        companyId: this.companyId,
        code: this.verification.code
      })
        .then(() => {
          this.toPreviousRoute()
          this.$nextTick(() => {
            this.$modal.show('truelayerIntegrationModal')
          })
        })
        .catch(e => {
          this.toPreviousRoute()
        })
    },
    getPrevRoute () {
      return JSON.parse(localStorage.getItem(LSKeys.truelayerIntegrationPrevRoute)) || { name: 'dashboard' }
    },
    toPreviousRoute () {
      this.$router.push(this.getPrevRoute())
    }
  },
  beforeMount () {
    this.verification = Object.keys(this.$route.query).reduce((acc, key) => {
      const value = this.$route.query[key]
      acc[key] = Array.isArray(value) ? value[0] : value
      return acc
    }, {})
    if (this.verification && 'code' in this.verification) {
      this.truelayerVerificationFlow()
    } else {
      setTimeout(() => {
        this.toPreviousRoute()
      }, 2000)
    }
  }
}
</script>
